import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderBussoComponent } from './header-busso/header-busso.component';
import { FooterBussoComponent } from './footer-busso/footer-busso.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderBussoComponent,
    FooterBussoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
